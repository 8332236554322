.App, .Admin, .Support {
  text-align: center;
  overflow-x: hidden;
}

.App .container{
  width: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  max-height: 10%;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  font-size: calc(12px + 2vmin);
  color: white;
}

.App-header .col-xs-1 {
  margin-right: 10px;
}

.App-header .row{
  justify-content: center;
}
.App-header .tag-line{
  font-size: 12px;
}

.App-main , .Admin-main, .Support-main{
  background-color: #cae9f3;
  min-height: 80vh;
  margin-top:12%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(1px + 2vmin);
  color: #282c34;
}

.App-footer {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1vmin);
  color: white;
}
.nav-drawer{
  height: 100%;
  background-color: #cae9f3 !important;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%230d3074' fill-opacity='0.08' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}
.App-footer .row , .App-header .row {
  margin-left: 5px;
}

.App-link {
  color: #61dafb;
}

/* Browser Dark Theme customization
root {
  --text-color: DarkBlue;
  --back-color: Azure;
  --border-color:slategrey;
}

@media (prefers-dark-interface) {
  root {
    --text-color: Azure;
    --back-color: DarkBlue;
    --border-color:Azure;
  } 
}
*/
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.splash-container{
  border-bottom: double slategrey;
  font-size : 14px;
  margin-top: 18%;
}

.app-store-link {
  height: 50px;
  margin : auto auto auto 39%;
  background: url(../../style/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg) no-repeat;
}

.splash-container .row div{
  padding : 2px;
  width : 100%;
  justify-content: center;  
}

.splash-container .play-link img {
  width: 25%;  
}

.splash-container .appstore-link-temp { 
  padding-bottom : 12%!important;
}

.search-container {
  border-bottom: double slategrey;
  margin-top : 10px;
  margin-bottom : 10px;
}

.search-container form{
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 5px 0px 20px 0px;
}
.search-container .form-select-station,
.search-container .form-select-service{
  min-width: 100%;
}

.form-group{
  margin:auto!important;
  justify-content: center;

}
.form-group svg{
  display: inline;
  margin: 5px;
  font-size: 24px;
}

.search-results{
  padding : 10px 2px 2px 10px!important;
  min-height:60vh;
}

.search-results .slick-track{
  height: auto!important;
}

.search-results .criteria{
  font-size:12px;
  padding-bottom: 15px;
}

.search-results .no-results{
  font-size:14px;
}

.schedule-container{
  min-width: 200px;
  max-width: 200px;
}

.schedule-card::before {
  position: relative;
  content: attr(data-watermark);
  opacity: 0.5;
  font-size: 0.8rem;
  font-style: italic;
}

.schedule-container .row {
  margin : 2px 10px 2px 2px;
  justify-content: center;
}
.schedule-container .row .col-xs-3{
  padding: 5px;
}
.schedule-container .container-fluid{
  padding:2px!important;
}
.station-col, .time-col{
  font-size: 12px;
  text-align: left;
}
.station-col .align-top, .time-col .align-top, .station-col .align-middle {
 padding : 5px 0px 20px 0px;
 
}
.action-col{
  margin :  auto;
  font-size:32px;
  float: right;
}
.center-col{
  font-size : 16px; 
}
.search-container .btn {
   width : 100%;
  margin-top : 10px;
}

.schedule-container{
  min-width : 100%;
  max-width: 100%;;
}
/*
.schedule-container .bg-light{
  background-color: transparent!important;
}
*/
  .form-group {
   width: 100%;
   font-size: 14px;
  }
  .schedule-card{
    font-size: 14px;
  }
  
  .search-container .btn {
    width : 100%;
    background-color: #282c34!important;
    border-color: #282c34!important;
  }

  .search-container .btn:hover {
    background-color: #282c34;
    border-color: #282c34;
  }
  .station-col, .time-col{
    font-size: 12px;
  }

.results-container {
  width : 95%!important;
}
.results-container .slick-next:before,
.results-container .slick-prev::before {
  color: #282c34;
}

.results-container .slick-next.slick-disabled:before,
.results-container .slick-prev.slick-disabled:before {
  color: white;
  background-color: initial;
}

.App-main{
  max-width: 540px;
  margin: 0 auto;
  background-color: transparent;
}

body{
  background-color: #cae9f3 !important;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%230d3074' fill-opacity='0.08' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}