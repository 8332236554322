.trip-container{
    max-width : 540px;
    margin: 0 auto;
    padding : 5px!important;
    
}
.trip-container .row{
    width : 100%;
    padding : 5px;
    margin: 0 auto;
    border-bottom: 1px dotted black;
    justify-content: center;
}
.trip-container .row .label-col{
    width: 50%;
    word-wrap: break-word;
    float : right;
}

.trip-container .row .value-col{
    width: 50%;
    word-wrap: break-word;
}
.trip-container .row .button-col{
    min-width : 50%;
   
}
.trip-container .row  .button-col button{
    margin : 0 auto;
    min-width: 50%;
}
.trip-container .row  .cancel-button button{ 
   float : left;
}

.trip-container .row  .submit-button button{
    float : right;
 }

 .stops-row .name-col{
     width : 60%;
     float : left;
 }
 .stops-row .stop-col{
    width : 15%;
}
.stops-row .stop-time-col{
    width : 25%;
    float : right;
}
