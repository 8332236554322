.Support-main section{
    border-bottom: 1px dotted;
    width :80%;
    padding: 10px;
}
.Support-main .quest {
    font-weight: normal; 
    text-align: left;
}

.Support-main .ans {
    font-weight: normal;
    padding-left : 25px;
    text-align: left;
}